.view-toggle-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.view-toggle-buttons .btn-outline-primary {
  color: #2b6777;
  border-color: #2b6777;
  margin: 0 2px;
}

.view-toggle-buttons .btn-outline-primary:hover {
  background-color: #2b6777;
  color: #ffffff;
}

.view-toggle-buttons .btn-primary {
  background-color: #52ab98;
  border-color: #52ab98;
  margin: 0 2px;
}

.view-toggle-buttons .btn-primary:hover {
  background-color: #2b6777;
  border-color: #2b6777;
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .view-toggle-buttons {
    flex-direction: column;
    align-items: stretch;
  }
  
  .view-toggle-buttons .btn {
    margin: 0.25rem 0 !important;
  }
}