.mainPage {
    height: 100vh;
    /* width: 100vh; */
    /* border: 1px solid rgb(0, 0, 0); */
    display: grid;
    gap: 10px;
    grid-template: 1fr 18fr 1fr / 1fr 2fr;
    grid-template-areas:
    "header header" 
    "envelopes transactions"
    "footer footer";
}

#mainNav {
    grid-area: header;
}

.navbar-brand {
    display: flex;
    flex-direction: column;
    line-height: 1;
    padding: 0;
}

.navbar-brand__title {
    font-size: 1.5rem;
    margin-bottom: 8px;
}

.navbar-brand__username {
    font-size: 1rem;
    opacity: 0.8;
    margin-top: 4px;
}

.mainEnvelopes {
    overflow-x: hidden;
    overflow-y: scroll;
    grid-area: envelopes;
}

.mainTransactions {
    overflow-x: hidden;
    overflow-y: auto;
    grid-area: transactions;
}

.mainFooter {
    grid-area: footer;
    background-color: #2b6777;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

/* Message Alert Styles */
.message-alert {
    color: #ffffff;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 4px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.message-alert__close-btn {
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-size: 20px;
    padding: 0 5px;
}

/* Modal Styles */
.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.ReactModal__Content {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    margin: 0 !important;
    transform: none !important;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.modal-expired {
    text-align: center;
}

.modal-expired__title {
    color: #721c24;
    margin-bottom: 1rem;
}

.modal-expired__message {
    margin-bottom: 1.5rem;
    color: #333;
}

.modal-expired__close-btn {
    background-color: #2b6777;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}
