.free-imports-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 20px;
    outline: none;
    max-width: 90%;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.free-imports-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(43, 103, 119, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    color: #2b6777;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.modal-header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #2b6777;
}

.close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #2b6777;
}

.modal-body {
    display: flex;
    flex-direction: column;
}

.image-container {
    text-align: center;
    margin-bottom: 20px;
}

.responsive-image {
    max-width: 100%;
    height: auto;
    max-height: 200px;
}

.text-content {
    font-size: 0.9rem;
}

.why-choose-section {
    background-color: #f2f2f2;
    padding: 15px;
    margin: 15px 0;
    border-radius: 5px;
}

.why-choose-section h3 {
    margin-top: 0;
    color: #2b6777;
}

.why-choose-section ul {
    padding-left: 20px;
    margin-bottom: 0;
}

.highlight-text {
    font-weight: bold;
    color: #52ab98;
}

.modal-footer {
    margin-top: 20px;
    text-align: right;
}

.btn-primary {
    background-color: #52ab98;
    border: none;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: #2b6777;
}

@media (min-width: 768px) {
    .free-imports-modal {
        width: 80%;
        max-width: 800px;
    }

    .modal-header h2 {
        font-size: 2rem;
    }

    .modal-body {
        flex-direction: row;
        align-items: flex-start;
    }

    .image-container {
        flex: 1;
        margin-right: 20px;
        margin-bottom: 0;
    }

    .responsive-image {
        max-height: 300px;
    }

    .text-content {
        flex: 2;
        font-size: 1rem;
    }
}

@media (max-width: 767px) {
    .free-imports-modal {
        padding: 15px;
    }

    .text-content ul {
        padding-left: 15px;
    }
}