.account-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.account-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.account-header h1 {
  color: #2b6777;
  margin: 0;
}

.back-button {
  background-color: #52ab98;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #2b6777;
}

.account-form {
  background-color: #f2f2f2;
  padding: 2rem;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #2b6777;
  font-weight: 500;
}

.form-control {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #c8d8e4;
  border-radius: 4px;
  background-color: #ffffff;
  transition: border-color 0.3s;
}

.form-control:focus {
  outline: none;
  border-color: #52ab98;
}

.current-value {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #52ab98;
}

.static-value {
  width: 100%;
  padding: 0.5rem;
  background-color: #f2f2f2;
  border: 1px solid #c8d8e4;
  border-radius: 4px;
  color: #2b6777;
  cursor: default;
}

select.form-control {
  cursor: pointer;
}

input[type="date"].form-control {
  cursor: pointer;
}
