.login-container {
  display: flex;
  height: 100vh;
  background-color: #f2f2f2;
}

.login-form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #ffffff;
}

.login-title {
  color: #2b6777;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.login-form {
  width: 100%;
  max-width: 400px;
}

.login-input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #c8d8e4;
  border-radius: 4px;
  font-size: 1rem;
}

.login-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.login-checkbox input {
  margin-right: 0.5rem;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #52ab98;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #2b6777;
}

.login-footer {
  margin-top: 1rem;
  color: #2b6777;
}

.login-footer a {
  color: #52ab98;
  text-decoration: none;
}

.login-signup {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2b6777;
  color: #ffffff;
  padding: 2rem;
}

.login-signup h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.login-signup p {
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

.signup-button {
  padding: 0.75rem 2rem;
  background-color: #ffffff;
  color: #2b6777;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signup-button:hover {
  background-color: #c8d8e4;
}

.alert {
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
}

.alert-info {
  background-color: #c8d8e4;
  color: #2b6777;
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
}

.hidden {
  display: none;
}

@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }

  .login-form-container,
  .login-signup {
    width: 100%;
  }
}