.category-grid-container {
  padding: 2rem 0;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.category-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
  max-height: 60vh;
  overflow-y: auto;
  padding: 1rem;
  border-radius: 8px;
  background-color: #f2f2f2;
  min-height: 200px;
}

.category-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 1rem;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  text-align: center;
  position: relative;
  border: 2px solid transparent;
}

.category-tile:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.category-tile.selected {
  border-color: #52ab98;
}

.category-icon {
  margin-bottom: 1rem;
  color: #2b6777;
  transition: color 0.3s;
}

.category-tile.selected .category-icon {
  color: #52ab98;
}

.category-tile:not(.selected) .category-icon {
  filter: grayscale(1);
}

.category-name {
  font-weight: bold;
  font-size: 1rem;
  color: #2b6777;
  margin-bottom: 0.2rem;
}

.category-type {
  font-size: 0.8rem;
  color: #666;
  font-style: italic;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 2rem;
}

.button-container button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
}

.btn-primary {
  background-color: #52ab98;
  border-color: #52ab98;
}

.btn-primary:hover {
  background-color: #2b6777;
  border-color: #2b6777;
}

.btn-secondary {
  background-color: #f2f2f2;
  border-color: #c8d8e4;
  color: #2b6777;
}

.btn-secondary:hover {
  background-color: #c8d8e4;
  border-color: #2b6777;
}

.no-categories-message {
  grid-column: 1 / -1;
  text-align: center;
  padding: 2rem;
  color: #2b6777;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.no-categories-message p {
  margin-bottom: 0.5rem;
}

.no-categories-message p:last-child {
  font-style: italic;
  color: #52ab98;
}

@media (max-width: 768px) {
  .category-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
  }
  
  .category-tile {
    padding: 1rem 0.5rem;
  }
  
  .button-container button {
    padding: 0.5rem 1rem;
  }
}