.register-container {
  min-height: 100vh;
}

.register-main {
  background-color: #ffffff;
  padding: 2rem;
}

.register-secondary {
  background-color: #2b6777;
  color: #ffffff;
}

.register-form-container {
  max-width: 400px;
  margin: 0 auto;
}

.register-form input,
.register-form select {
  margin-bottom: 1rem;
}

.register-title {
  color: #2b6777;
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.register-button {
  background-color: #52ab98;
  border-color: #52ab98;
  width: 100%;
}

.register-button:hover {
  background-color: #2b6777;
  border-color: #2b6777;
}

.register-link {
  color: #52ab98;
}

.register-link:hover {
  color: #2b6777;
}

.money-quote {
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}

.money-saying {
  font-size: 2rem;
  font-style: italic;
  margin-bottom: 1.5rem;
}

.money-author {
  font-size: 1.5rem;
  font-weight: bold;
}