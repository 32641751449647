/* Card Styles */
.card {
  background-color: #fff;
  border-radius: 10px;
  border: none;
  position: relative;
  margin-bottom: 5px;
  cursor: pointer;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1),
              0 0.9375rem 1.40625rem rgba(90,97,105,0.1),
              0 0.25rem 0.53125rem rgba(90,97,105,0.12),
              0 0.125rem 0.1875rem rgba(90,97,105,0.1);
}

.card .card-statistic-3 .card-icon-large .fas,
.card .card-statistic-3 .card-icon-large .far,
.card .card-statistic-3 .card-icon-large .fab,
.card .card-statistic-3 .card-icon-large .fal {
  font-size: 110px;
}

.card-negative {
  color: red;
}

.card .card-statistic-3 .card-icon {
  text-align: center;
  line-height: 50px;
  margin-left: 15px;
  color: #000;
  position: absolute;
  right: -5px;
  top: 20px;
  opacity: 0.1;
}

/* Background Styles */
.l-bg-cherry {
  background: linear-gradient(to right, #493240, #f09);
  color: #fff;
}

.l-bg-blue-dark {
  background: linear-gradient(to right, #373b44, #4286f4);
  color: #fff;
}

.l-bg-green-dark {
  background: linear-gradient(to right, #0a504a, #2cd06b);
  color: #fff;
}

.l-bg-orange-dark {
  background: linear-gradient(to right, #a86008, #ffba56);
  color: #fff;
}

.l-bg-cyan {
  background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
  color: #fff;
}

/* Progress Bar Styles */
.progress-container {
  position: relative;
  width: 100%;
  margin-top: 1rem;
}

.progress {
  background-color: rgba(0,0,0,0.1);
  border-radius: 4px;
  overflow: visible !important;
  height: 8px;
}

.progress-bar {
  transition: width 0.6s ease;
}

.l-bg-green {
  background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%) !important;
  color: #fff;
}

.l-bg-orange {
  background: linear-gradient(to right, #f9900e, #ffba56) !important;
  color: #fff;
}

/* Tickmark Styles */
.progress-tick {
  position: absolute;
  top: -8px;
  width: 1px;
  height: 24px;
  background-color: #000000;
  transform: translateX(-50%);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .card .card-statistic-3 .card-icon-large {
    font-size: 80px;
  }
}