.trans-reconciliation-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 35%;
  max-height: 95%;
  overflow-y: auto;
}

.trans-reconciliation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.trans-reconciliation-container {
  font-family: Arial, sans-serif;
}

.trans-reconciliation-container h2 {
  color: #2b6777;
  margin-bottom: 20px;
}

.trans-reconciliation-container h5 {
  color: #52ab98;
  margin-top: 15px;
  margin-bottom: 10px;
}

.trans-reconciliation-container label {
  display: block;
  margin-bottom: 5px;
}

.trans-reconciliation-container .radio-input {
  margin-right: 5px;
}

.trans-reconciliation-container .btn {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.trans-reconciliation-container .btn-primary {
  background-color: #2b6777;
  color: #ffffff;
}

.trans-reconciliation-container .btn-primary:hover {
  background-color: #52ab98;
}

.trans-reconciliation-container .btn-cancel {
  background-color: #c8d8e4;
  color: #2b6777;
}

.trans-reconciliation-container .btn-cancel:hover {
  background-color: #f2f2f2;
}