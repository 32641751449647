.transaction-container {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.transaction-container:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.transaction-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transaction-date {
  font-size: 0.85rem;
  color: #666;
  width: 25%;
}

.transaction-payee-wrapper {
  display: flex;
  align-items: center;
  width: 50%;
}

.transaction-status {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  color: #4CAF50;
  margin-right: 6px;
  width: 16px;
}

.transaction-payee {
  font-size: 0.95rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
}

.transaction-amount {
  font-size: 0.95rem;
  font-weight: 500;
  width: 25%;
  text-align: right;
}

.transaction-details {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.8rem;
  color: #888;
}

.transaction-balance {
  text-align: right;
}