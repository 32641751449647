/* AddTransaction.css */
.add-transaction-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.add-transaction-modal {
  background-color: #f0f4f7; /* Light gray background */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  max-height: 85vh; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.add-transaction-container {
  display: flex;
  flex-direction: column;
}

.modal-title {
  color: #2b6777;
  font-size: 1.75rem;
  margin-bottom: 1rem;
  text-align: center;
}

.form-group {
  margin-bottom: 0.75rem; /* Reduced margin-bottom */
}

.form-group label {
  display: block;
  margin-bottom: 0.3rem;
  color: #2b6777;
  font-weight: bold;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.4rem;
  border: 1px solid #c8d8e4;
  border-radius: 4px;
  font-size: 0.9rem;
  background-color: #ffffff;
}

.amount-input-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.amount-input-wrapper {
  position: relative;
  flex-grow: 1;
  margin-right: 1rem;
}

.currency-symbol {
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: #2b6777;
}

.amount-input-wrapper input {
  padding-left: 1.5rem;
}

.debit-credit-toggle {
  display: flex;
  align-items: center;
}

.debit-credit-toggle input[type="radio"] {
  display: none;
}

.debit-credit-toggle label {
  padding: 0.4rem 0.8rem;
  background-color: #ffffff;
  color: #2b6777;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.debit-credit-toggle input[type="radio"]:checked + label {
  background-color: #52ab98;
  color: #ffffff;
}

.debit-credit-toggle label:first-of-type {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.debit-credit-toggle label:last-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.button-group button {
  flex: 1 0 calc(50% - 0.25rem);
  padding: 0.6rem;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.btn-primary {
  background-color: #2b6777;
  color: #ffffff;
}

.btn-secondary {
  background-color: #52ab98;
  color: #ffffff;
}

.btn-danger {
  background-color: #ff6b6b;
  color: #ffffff;
}

.btn-cancel {
  background-color: #ffffff;
  color: #2b6777;
}

.btn-primary:hover,
.btn-secondary:hover,
.btn-danger:hover,
.btn-cancel:hover {
  opacity: 0.9;
}

@media (max-width: 600px) {
  .add-transaction-modal {
    width: 95%;
    padding: 1rem;
  }

  .button-group button {
    flex: 1 0 100%;
  }
}

/* Maintain existing styles for number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.credit {
  background-color: #6aeb8d !important;
}

/* Styles for EnvelopeBreakout component */
.envelope-breakout {
  margin-bottom: 1.5rem; /* Increased margin-bottom */
  max-height: 300px; /* Adjust this value as needed */
  overflow-y: auto;
}

.envelope-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
}

.envelope-select {
  flex: 2;
  margin-right: 0.5rem;
}

.envelope-amount {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
}

.envelope-amount-wrapper {
  position: relative;
  width: 100%;
}

.envelope-amount input {
  padding-left: 1.5rem;
  width: 100%;
}

.envelope-amount .currency-symbol {
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: #2b6777;
}

.add-envelope-button {
  background-color: #52ab98;
  color: #ffffff;
  border: none;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 0.4rem;
  width: 100%;
}

.add-envelope-button:hover {
  background-color: #449a8a;
}

.remove-envelope-button {
  background-color: #ff6b6b;
  color: #ffffff;
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 0.4rem;
  font-size: 0.9rem;
  line-height: 1;
  flex-shrink: 0;
  padding: 0;
}

.remove-envelope-button:hover {
  background-color: #ff5252;
}