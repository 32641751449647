.privacy-policy-page {
    background-color: #f2f2f2;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .privacy-content {
    flex-grow: 1;
    padding: 40px 0;
  }
  
  .privacy-content h1 {
    color: #2b6777;
    margin-bottom: 20px;
  }
  
  .privacy-content h2 {
    color: #52ab98;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .privacy-content p {
    margin-bottom: 15px;
  }
  
  .privacy-content ul {
    margin-bottom: 15px;
    padding-left: 20px;
  }
  
  .privacy-content li {
    margin-bottom: 5px;
  }
  
  .privacy-content section {
    margin-bottom: 30px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .privacy-content {
      padding: 20px 0;
    }
  
    .privacy-content h1 {
      font-size: 24px;
    }
  
    .privacy-content h2 {
      font-size: 20px;
    }
  }
  
  /* Navbar styles */
  .navbar-dark .navbar-nav .nav-link {
    color: #ffffff;
  }
  
  .navbar-dark .navbar-nav .nav-link:hover {
    color: #c8d8e4;
  }
  
  /* Footer styles */
  footer {
    margin-top: auto;
  }
  
  footer h5 {
    color: #ffffff;
    margin-bottom: 15px;
  }
  
  footer ul {
    padding-left: 0;
  }
  
  footer li {
    margin-bottom: 5px;
  }