.add-envelope-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .add-envelope-modal {
    background-color: #f0f4f7;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 1.5rem;
    position: relative;
  }
  
  .add-envelope-container {
    display: flex;
    flex-direction: column;
  }
  
  .modal-title {
    color: #2b6777;
    font-size: 1.75rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.3rem;
    color: #2b6777;
    font-weight: bold;
  }

  /* Style for form groups containing disabled inputs */
  .form-group:has(input:disabled) {
    opacity: 0.5;
    cursor: not-allowed;
  }

  /* Style for radio groups containing disabled inputs */
  .radio-group:has(input:disabled) {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .form-control {
    width: 100%;
    padding: 0.4rem;
    border: 1px solid #c8d8e4;
    border-radius: 4px;
    font-size: 0.9rem;
    background-color: #ffffff;
  }

  /* Styles for the combobox input */
  input[list] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%232b6777' d='M2 4l4 4 4-4'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.4rem center;
    padding-right: 1.5rem;
  }

  input[list]:focus {
    border-color: #2b6777;
    outline: none;
    box-shadow: 0 0 0 2px rgba(43, 103, 119, 0.2);
  }
  
  .radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .radio-group label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .radio-group input[type="radio"] {
    margin-right: 0.5rem;
  }
  
  .checkbox-group {
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
  }
  
  .checkbox-group.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .checkbox-group input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #f0f4f7;
    border: 2px solid #2b6777;
    border-radius: 4px;
  }
  
  .checkbox-group:hover input:not([disabled]) ~ .checkmark {
    background-color: #ccc;
  }
  
  .checkbox-group input:checked ~ .checkmark {
    background-color: #2b6777;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .checkbox-group input:checked ~ .checkmark:after {
    display: block;
  }
  
  .checkbox-group .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  
  .input-group {
    display: flex;
    align-items: center;
  }
  
  .input-group-text {
    background-color: #c8d8e4;
    border: 1px solid #c8d8e4;
    border-right: none;
    border-radius: 4px 0 0 4px;
    padding: 0.4rem 0.6rem;
    color: #2b6777;
  }
  
  .input-group .form-control {
    border-left: none;
    border-radius: 0 4px 4px 0;
  }
  
  .preview-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .preview-container label {
    display: block;
    margin-bottom: 0.5rem;
    color: #2b6777;
    font-weight: bold;
  }
  
  .button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
  }
  
  .button-group button {
    flex: 1 0 calc(50% - 0.25rem);
    padding: 0.6rem;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .btn-primary {
    background-color: #2b6777;
    color: #ffffff;
  }
  
  .btn-secondary {
    background-color: #52ab98;
    color: #ffffff;
  }
  
  .btn-cancel {
    background-color: #ffffff;
    color: #2b6777;
    border: 1px solid #2b6777;
  }
  
  .btn-primary:hover,
  .btn-secondary:hover {
    opacity: 0.9;
  }
  
  .btn-cancel:hover {
    background-color: #f2f2f2;
  }

  .alert {
    position: relative;
    padding: 0.75rem 2rem 0.75rem 1rem;
    margin-bottom: 1rem;
    border-radius: 4px;
  }

  .alert-info {
    background-color: #c8d8e4;
    color: #2b6777;
  }

  .alert-danger {
    background-color: #f8d7da;
    color: #721c24;
  }

  .alert-dismiss {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 1.25rem;
    color: inherit;
    cursor: pointer;
    padding: 0.25rem;
    line-height: 1;
  }

  .alert-dismiss:hover {
    opacity: 0.7;
  }
  
  @media (max-width: 600px) {
    .add-envelope-modal {
      width: 95%;
      padding: 1rem;
    }
  
    .button-group button {
      flex: 1 0 100%;
    }
  }
