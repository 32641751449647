.importPage {
    height: 95vh;
    display: grid;
    gap: 10px;
    grid-template: 1fr 18fr 1fr / 2fr 2fr 1fr;
    grid-template-areas:
    "header header header" 
    "bankTrans dbTrans envelopes"
    "footer footer footer";
}

.importHeader {
    grid-area: header;
}

.importBankTrans {
    grid-area: bankTrans;
    overflow-x: auto;
    overflow-y: auto;
}

.importDBTrans {
    grid-area: dbTrans;
    overflow-x: hidden;
    overflow-y: auto;
}

.importEnvelopes {
    grid-area: envelopes;
    overflow-x: hidden;
    overflow-y: auto;
}

.importFooter {
    grid-area: footer;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.overMatch {
    background-color: lightgreen;
}

.match { background-color: greenyellow;}

.radio-input {margin-right: 8px;}

.instructions {
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out, margin-bottom 0.3s ease-out;
    max-height: 1000px;
    opacity: 1;
    overflow: hidden;
}

.instructions.hidden {
    max-height: 0;
    opacity: 0;
    margin-bottom: 0;
}